import { Chip } from "@material-ui/core";
import { green, yellow } from "@material-ui/core/colors";
import { CSSProperties } from "react";

interface ControlProps {
  status: string;
}

const StatusChip : React.FC<ControlProps> = ({status}) => {
  let chipStyle : CSSProperties = {height: 24};

  switch(status){
    case "Rendered":
      chipStyle = {...chipStyle, background: green[500], color: "#FFF"};
      break;

      case "Queued":
        chipStyle = {...chipStyle, background: yellow[500], color: "#000"};
        break;
    }

  return <Chip label={status} style={chipStyle} />;
};

export default StatusChip;