import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ReportingView from "features/reporting/reporting-view";
import { useCustomizations } from "@personicom/customizations";

const buildStyles   = makeStyles(theme => ({
  root  : (props: any) => ({
    padding     : props.noFrame ? 0 : theme.spacing(1),
    // minHeight  : "calc(100vh - 120px)",
  }),
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
}));

const AppBody : React.FC = () => {
  const { isIframe } = useCustomizations();
  const classes   = buildStyles({noFrame: isIframe});

  return (
    <Grid id="app-body" container justify="center" className={classes.root}>
      <Router>
        <Route path="/" component={ReportingView} />
      </Router>
    </Grid>
  );
}

export default AppBody;
