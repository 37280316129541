import { CSSProperties, useMemo } from "react";
import { keys, compact, isArray } from "lodash";
import cc from 'classcat';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, } from "@material-ui/core";
import FilterField from "./report-filter-field";
import { parseFilter } from "./reporting-helpers";
import { ReportGridConfig } from "app-types";

const buildStyles   = makeStyles(theme => ({
  root: {
    padding     : `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
  },
  actionButton: {
    marginLeft: theme.spacing(1),
    boxShadow: "none",
    marginBottom: theme.spacing(-0.5),
  },
  textField: {
    maxWidth: 175,
    minWidth: 85,
    "& .MuiOutlinedInput-input": {
      padding: 10.5,
    }
  },
  datePicker: {
    maxWidth: 155,
    "& .MuiOutlinedInput-input": {
      padding: 10.5,
    },
    "& .MuiIconButton-root" : {
      padding: theme.spacing(0.5),
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: theme.spacing(0.25),
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: theme.spacing(0.5),
    }
  },
  select: {
    "& .MuiOutlinedInput-input": {
      padding: 10.5,
    },
    minWidth: 150,
    maxWidth: 175,
  }
}));

// interface GridConfig {
//   filters: any[],
//   classes: Record<string, any>,
// };

interface FilterProps {
  config: ReportGridConfig;
  classes: CSSProperties,
  props?: any;
  currentFilter: any;
  isWorking?: boolean;
  onFilter: (filterString: string) => void;
  onExport: () => void;
  onRefresh: () => void;
}

const ReportFilter : React.FC<FilterProps> = (controlProps) => {
  const classes = buildStyles();
  const { config, classes: parentClasses, currentFilter, isWorking, onFilter, onExport, onRefresh} = controlProps;
  const activeFilters = useMemo(() => config?.filters?.filter(f => !f.isHidden), [config?.filters]);
  if(!activeFilters) return null;
  const filterObj = parseFilter(currentFilter);

  const doFilter = (key: string | string[], value: string | string[]) => {
    const filterKeys = isArray(key) ? key : [key];
    const filterValues = isArray(value) ? value : [value];
    let newFilter = {...filterObj};
    let newKeys = keys(newFilter);

    for(let i = 0; i < filterKeys.length; i++){
      const key = filterKeys[i];
      const value = filterValues[i];

      if(filterObj[key] !== value){
        newFilter  = {...newFilter, [key]: value} as Record<string, any>;
        newKeys = keys(newFilter);
      } // else same as before, don't do anything
    }
    
    const filterItems   = newKeys.map(key => { return newFilter[key] ? `${key}~${newFilter[key]}` : null; } ); //`
    const filterString  = compact(filterItems).join("|");
    console.log("filter: ", filterItems, filterString);

    onFilter(filterString);
  }

  return (
    <Grid container spacing={1} className={cc([classes.root, parentClasses?.filter as string])}>
      <Grid item xs={10} container spacing={1} justify="flex-start" alignItems="center" >
        { 
          activeFilters.map((item: any) => {
            return (
              <Grid item key={item.key}>
                <FilterField config={item} cols={config.columns} isWorking={isWorking} filters={filterObj} classes={classes} onChange={doFilter} />
              </Grid>
            )
          })
        }
        <Button color="primary" variant="contained" className={classes.actionButton} disabled={isWorking} onClick={onRefresh}>Search</Button>
      </Grid>
      <Grid item xs={2} container justify="flex-end" alignItems="center">
        {onExport && <Button color="primary" variant="contained" className={classes.actionButton} disabled={isWorking} onClick={onExport}>Export List</Button>}
      </Grid>
    </Grid>
  );
}

export default ReportFilter;
