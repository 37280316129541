import { ReportFilterConfig } from './../app-types';
import { subHours } from "date-fns";
import { isArray, isObject, keys } from 'lodash';

export const DATE_FMT = "yyyy-MM-dd";


//===
// Gets a search parameter from the url by its name.  assumes search will be in the form: "?key=value&key2=value2"
export function getSearchParam(key: string, keepCase = false) : string | null {
  let searchVal = window.location.search.slice(1);
  if(!keepCase) searchVal = searchVal.toLowerCase();
  const search = new URLSearchParams(searchVal);
  return search.get(key);
}

//===
// Updates the QueryString in the url, either adding/updating or removing a value
export function updateUrlSearch(key: string, value: string | null) : string {
  const url = new URL(window.location.href);
  if (url.searchParams.has(key) && !value)
    url.searchParams.delete(key);
  else if (value && Boolean(value))
    url.searchParams.set(key, value);

  const relative = url.href.replace(window.location.origin, ""); //need to remove the origin for sake of the history
  return relative;
}

//===
//Gets a date object in UTC time
export function DateWithoutTz(dateString: string | undefined = undefined) : Date {
  const tzOffset = new Date().getTimezoneOffset() / 60;
  const baseDate = dateString ? new Date(dateString) : new Date();
  const result = subHours(baseDate, tzOffset);
  return result;
}

//===
// Merges the two filter arrays in a way that allows for overrides in the subdomain array
// which are merged into the brand array.
export const mergeComplexArrays = (firstArray : any[], secondArray : any[] | undefined, keyField: string = "id") : any[] => {
  if(!secondArray) return firstArray;
  let working = [...firstArray];

  secondArray.forEach(item => {
    const index = working.findIndex(w => w[keyField] === item[keyField]);
    if(index < 0){
      working = [...working, item];
    }
    else{
      // const mergedItem = {...working[index], ...item};
      const mergedItem = mergeComplexObjects(working[index], item);
      working[index] = mergedItem;
    }
  });

  return working;
}

//===
// Merges the two filter arrays in a way that allows for overrides in the subdomain array
// which are merged into the brand array.
export const mergeComplexObjects = (firstObj : Record<string, any>, secondObj : Record<string, any> | undefined) : Record<string, any> => {
  if(!secondObj) return firstObj;
  let working = {...firstObj};

  keys(secondObj).forEach(key => {
    if(isArray(secondObj[key]) && isArray(firstObj[key])){
      const newValue = mergeComplexArrays(firstObj[key], secondObj[key]);
      working[key] = newValue;
    }
    else if(isObject(secondObj[key]) && isObject(firstObj[key])){
      const newValue = mergeComplexObjects(firstObj[key], secondObj[key]);
      working[key] = newValue;
    }
    else{
      working[key] = secondObj[key];
    }
  });  

  return working;
}

//===
// Gets whether or not the app is running inside an iFrame
export function getIsInIframe () {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

function fallbackCopyTextToClipboard(text: string) {
  let result = false;
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
    result = Boolean(successful);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
  return result;
}

export async function copyTextToClipboard(text: string) : Promise<boolean> {
  const promise = new Promise<boolean>((resolve, reject) => {
    let result = false;

    if (!navigator.clipboard) {
      result = fallbackCopyTextToClipboard(text);
      resolve(result);
    }
    else{
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
        resolve(true);
      }, function(err) {
        console.error('Async: Could not copy text: ', err, ', trying fallback');
        result = fallbackCopyTextToClipboard(text);
        resolve(result);
      });
    } 
    
  });

  return promise;
}