import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, TableContainer, Typography } from "@material-ui/core";
import TimelineItem from "./timeline-item";
import { useAppState } from "../../helpers/hooks";
import { ITimelineEvent } from "./report-timeline-types";

const buildStyles   = makeStyles(theme => ({
  sectionHeader: {
    fontSize    : 16,
    fontWeight  : 600,
    marginBottom: theme.spacing(1),
  },
  detailsTable: {
    borderBottom: "none",
    marginBottom: theme.spacing(3),
  },   
}));

interface IReportTimelineProps {
  reportData: any;
}

const ReportTimeline : React.FC<IReportTimelineProps> = ({ reportData}) => {
  const classes = buildStyles();
  const { reportConfig, isWorking } = useAppState();
  
  const events = useMemo<ITimelineEvent[]>(() => {
    return reportData?.reportDetails ? reportData.reportDetails as ITimelineEvent[]: [];
  }, [reportData]);

  return (
    <div id="report-timeline">
      <Typography className={classes.sectionHeader}>Timeline</Typography>
        
      <TableContainer variant="outlined" elevation={0} component={Paper} className={classes.detailsTable}>
        {events.map(event => {
          return <TimelineItem key={event.eventId} event={event} config={reportConfig?.events} isWorking={isWorking}/>
        })}
      </TableContainer>
    </div>
  );
}

export default ReportTimeline;
