import { useEffect, useState } from "react";
import CustomizationsProvider, { CustomizationHelmet } from "@personicom/customizations";
import { getBaseUrl } from "@personicom/customizations";
import AppHeader from "./app-header";
import AppBody from "./app-body";
import AppDrawer from "./app-drawer";
import { Grid } from "@material-ui/core";
import ErrorCard from "components/error-card";
import { initializeXGrid } from "components/xgrid-helpers";
import { AppStateProvider } from "app-state";
import LoadingIndicator from "components/loading-indicator";

const GA_TRACKER = "UA-102377011-8";
const GA_TRACKER_SECONDARY = "UA-157273197-1";    //Fallback, in case it's not found in the layout.json

const App: React.FC = () => {
  const [isReady, setReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [path, setPath] = useState("");

  useEffect(() => {
    async function initialize(){
      try{
        initializeXGrid();
        const basePath = await getBaseUrl();
        setPath(basePath);
        setReady(true);
      }
      catch(ex){
        setError(ex);
      }
    }

    initialize();
  }, []);

  if(error){
    return (
      <Grid container direction="column" alignItems="center" style={{marginTop: "10%"}}>
        <ErrorCard message={`This appears to be an invalid domain. Please try again with a valid url.`} details={error.toString()} />
      </Grid>
    );
  }
  else if(!isReady){
    return <LoadingIndicator />;
  }
  else{
    return (
      <CustomizationsProvider path={path} >   
        <CustomizationHelmet gaTrackerId={GA_TRACKER} gaTrackerSecondaryId={GA_TRACKER_SECONDARY} />
        <AppStateProvider>
          <AppHeader />
          <AppBody/>
          <AppDrawer />
        </AppStateProvider>
      </CustomizationsProvider>
    );
  }
}

export default App;
