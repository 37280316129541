import { CircularProgress, Grid, Typography } from "@material-ui/core";

interface ControlProps {
  message?: string;
}

const LoadingIndicator : React.FC<ControlProps>  = ({message}) => {
  return (
    <Grid container direction="column" alignItems="center" style={{marginTop: "10%"}}>
      <CircularProgress color="primary" />
      <Typography component="span" color="textSecondary" style={{marginTop: "8px"}}>{message ?? "Initializing..."}</Typography>
    </Grid>
  );
}

export default LoadingIndicator;
