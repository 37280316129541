import { useAppState } from "./helpers/hooks";
import { Drawer } from "@material-ui/core";
import ReportDetails from "./features/report-details/report-details";

const AppDrawer : React.FC = () => {
  const { selectedRow, actions } = useAppState();

  return (
    <Drawer anchor="right" open={Boolean(selectedRow)} onClose={() => actions.setSelectedRow(null)}>
      <ReportDetails/>
    </Drawer>
  );
}

export default AppDrawer;
