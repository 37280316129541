import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IReportDetailRow } from "./report-detail-types";
import { getTemplateValue } from "../reporting/reporting-helpers";
import StatusChip from 'components/status-chip';

const buildStyles   = makeStyles(theme => ({
  value: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

interface IValueProps {
  data: any;
  row: IReportDetailRow;
}

const RowValue : React.FC<IValueProps> = ({row, data}) => {
  switch(row.type){
    case "link": return <LinkValue row={row} data={data} />;
    case "status": return <StatusValue row={row} data={data} />;
    case "text":
    default:
      return <TextValue row={row} data={data} />;
  }
}

export default RowValue;

//===
// Value component for the Row.type of "text" (or empty - this is the default)
const TextValue : React.FC<IValueProps> = ({row, data}) => {
  const classes = buildStyles();
  const value = useMemo(() => getTemplateValue(row.value, data, ""), [row, data]);

  return (
    <Typography className={classes.value}>{value}</Typography>
  );
}

//===
// Value component for the Row.type of "link"
const LinkValue : React.FC<IValueProps> = ({row, data}) => {
  const classes = buildStyles();
  const { link } = row;
  const value = useMemo(() => getTemplateValue(row.value, data, ""), [row, data]);
  const prefix = useMemo(() => link?.type === "mailto" ? "mailto:" : "", [link]);
  //Look for a url in the link property, and fallback to the value from the row, if necessary
  const url = useMemo(() => link?.url ? getTemplateValue(link.url, data, "") : value, [link, data, value]);
  const text = useMemo(() => link?.text ? getTemplateValue(link.text, data, "Click Here") : value, [link, data, value]);
  
  //If no link properties, then just display a text value
  if(!link) return <TextValue row={row} data={data} />;  

  //TODO: deal with download - the download attribute isn't working (possibly because of: https://medium.com/charisol-community/downloading-resources-in-html5-a-download-may-not-work-as-expected-bf63546e2baa)

  return (
    <Typography className={classes.value}>{value ? <a href={`${prefix}${url}`} download={link.type === "download" ? true : false} target={link.target || "_blank"} rel="noreferrer">{text || "Click Here"}</a> : null}</Typography>
  )
};

//===
// Value component for the Row.type of "status"
const StatusValue : React.FC<IValueProps> = ({row, data}) => {
  const value = useMemo(() => getTemplateValue(row.value, data, ""), [row, data]);
  
  return (
    <StatusChip status={value}/>
  );
}

