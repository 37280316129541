import React, { useEffect, useMemo, useState } from 'react';
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { getFilterValue } from './reporting-helpers';
import { getApiBaseUrl } from "./reporting-helpers";
import _ from 'lodash';
import { doFetchWithToken } from 'helpers/api-helpers';
import { ReportFilterOptionsConfig } from 'app-types';
import { IFilterFieldProps } from "./reporting-types";
import { useCustomizations } from '@personicom/customizations';
import { useAppState } from 'helpers/hooks';
import DateFilter from "./filters/date-filter";
import DateRangeFilter from "./filters/date-range-filter";
import ErrorBoundary from 'components/error-boundary';

const FilterField : React.FC<IFilterFieldProps> = ({config, cols, filters, classes, isWorking, onChange}) => {
  return (
    <ErrorBoundary>
      {config.type === "text" && <TextFilter config={config} cols={cols} filters={filters} classes={classes} isWorking={isWorking} onChange={onChange} />}
      {config.type === "date" && <DateFilter config={config} cols={cols} filters={filters} classes={classes} isWorking={isWorking} onChange={onChange} />}
      {config.type === "dateRange" && <DateRangeFilter config={config} cols={cols} filters={filters} classes={classes} isWorking={isWorking} onChange={onChange} />}
      {config.type === "select" && <SelectFilter config={config} cols={cols} filters={filters} classes={classes} isWorking={isWorking} onChange={onChange} />}
      {config.type === "columnFilter" && <ColumnFilter config={config} cols={cols} filters={filters} classes={classes} isWorking={isWorking} onChange={onChange} />}
      {config.type === "span" && <Typography className={classes.span}>{config.label}</Typography>}      
    </ErrorBoundary>
  )
}

export default FilterField;

//---- Text Filter Field
const TextFilter : React.FC<IFilterFieldProps> = ({config, filters, classes, isWorking, onChange}) => {
  const defaultValue      = getFilterValue(config.defaultValue);
  const [tempValue, setTempValue]   = useState(defaultValue);

  const onInputChanged = (e : React.ChangeEvent<HTMLInputElement>) => {
    const value   = e.currentTarget.value;
    setTempValue(value);
  }

  const onInputBlur = (e : React.FocusEvent<HTMLInputElement>) => {
    if(tempValue !== filters[config.key]){
      onChange(config.key, tempValue); //Notify the parent that we're filtering on this field now
    }
  }

  //TODO: handle return press in the input
    
  return (
    <TextField id={config.key} className={classes.textField} variant="outlined" margin="dense" value={tempValue} onChange={onInputChanged} onBlur={onInputBlur} placeholder={config.label} title={config.label} disabled={isWorking}/>
  )
}



//---- Select Filter Field
const SelectFilter : React.FC<IFilterFieldProps> = ({config, filters, classes, isWorking, onChange}) => {
  const defaultValue  = getFilterValue(config.defaultValue);
  const value         = filters[config.key] || defaultValue;
  const optConfig = config.options as ReportFilterOptionsConfig;
  const [options, setOptions] = useState<Record<string, any>[]>([]);
  const { token } = useAppState();
  const { client, subdomain } = useCustomizations();
  
  useEffect(() => {
    async function loadItemsAsync(source: string){
      try{
        const urlBase = getApiBaseUrl("");
        const c1 = (urlBase.endsWith("/") || source.startsWith("/")) ? "" : "/";
        let url = `${urlBase}${c1}${source}`;
        const qs = `rootDomain=${client}&subdomain=${subdomain}`;
        const c2 = url.indexOf("?") >= 0 ? "&" : "?";
        url = `${url}${c2}${qs}`;

        const itemsJson = await doFetchWithToken(url, "GET", null, token ?? "");
        setOptions(itemsJson);
        // console.log("campaigns", itemsJson);
      }
      catch(ex){
        console.error(`Failed to load items for select filter ${config.key}`, ex);
      }
    }

    if(optConfig.itemsSource) loadItemsAsync(optConfig.itemsSource);
  }, [optConfig]);

  const onSelectChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value   = e.target.value;
    if(value !== filters[config.key]) onChange(config.key, value);
  }

  if(!options || options.length === 0) return null; //nothing to display without options

  return (
    <TextField id={config.key} select value={value} defaultValue={defaultValue} onChange={onSelectChanged} label={config.label} title={config.tooltip || config.label} disabled={isWorking} className={classes?.select} variant="outlined" margin="dense">
      <MenuItem value="">{config.emptyLabel}</MenuItem>
      { _.map(options, opt => <MenuItem key={opt[optConfig.keyProp]} value={opt[optConfig.keyProp]}>{opt[optConfig.labelProp]}</MenuItem>)}
    </TextField>
  )
}

//---- Column Filter Field - a filter that lets the user choose a column (based on the grid columns) and enter an arbitrary value
const ColumnFilter : React.FC<IFilterFieldProps> = ({config, cols, filters, classes, isWorking, onChange}) => {
  const [colValue, setColValue] = useState("");
  const [prevCol, setPrevCol] = useState("");
  const [tempValue, setTempValue]   = useState("");
  const options = useMemo(() => cols.filter(c => c.filterable === true), [cols]);
  
  const onSelectChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cVal   = e.target.value;
    if(cVal !== colValue){
      setColValue(cVal);
      setTempValue("");
    } 

    //if they selected none, clear out the search filter
    if(cVal === "" && prevCol){
      onChange(prevCol, "");
      setPrevCol("");
    }
  }
  
  const onInputChanged = (e : React.ChangeEvent<HTMLInputElement>) => {
    const value   = e.currentTarget.value;
    setTempValue(value);
  }

  const onInputBlur = (e : React.FocusEvent<HTMLInputElement>) => {
    if(tempValue !== filters[colValue] && colValue){
      if(prevCol){
        //need to clear out the previous column filter since this is a different key value.
        onChange([prevCol, colValue], ["", tempValue]);
      }
      else{
        onChange(colValue, tempValue); //Notify the parent that we're filtering on this field now
      }
      setPrevCol(colValue);
    }
  }

  return (
    <Grid container item spacing={1}>
      <Grid item>
        <TextField id={`${config.key}-column`} select value={colValue} onChange={onSelectChanged} label={config.label} title={config.tooltip || config.label} disabled={isWorking} className={classes?.select} variant="outlined" margin="dense">
          <MenuItem value="">{config.emptyLabel ?? "- none -"}</MenuItem>
          { _.map(options, opt => <MenuItem key={opt.field} value={opt.field}>{opt.headerName}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item>
        <TextField id={config.key} className={classes.textField} variant="outlined" margin="dense" value={tempValue} onChange={onInputChanged} onBlur={onInputBlur} placeholder="column value" title={config.label} disabled={isWorking || !colValue}/>
      </Grid>
    </Grid>
  )
}