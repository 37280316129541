import React, { useMemo, useState } from "react";
import { format, subDays } from "date-fns";
import { FilterQueryParams, IPayloadError, PageInfo, PaginationInfo, ReportPayload, ReportRow, ReportGridConfig } from "./app-types";
import { useReportConfig } from "helpers/hooks";
import { getReportData, getReportDetails, getReportDownload, replaceItemDetails } from "features/reporting/reporting-helpers";
import { parsePagination } from "helpers/api-helpers";
import { useCustomizations } from "@personicom/customizations";
import { getSearchParam } from "helpers/general-helpers";
import { DATEPICKER_FORMAT } from "features/reporting/filters/date-filter";
import { useEffect } from "react";
import { startOfMonth } from "date-fns/esm";

interface IAppState { //extends ReportPayload {
  isWorking: boolean;  
  page?: PageInfo;  
  pagination?: PaginationInfo;
  items?: any[];
  error?: IPayloadError | null,
  selectedRow?: any,
  reportConfig?: ReportGridConfig;
  actions: {
    setWorking: (val: boolean) => void,
    setPage: (val: PageInfo) => void,
    fetchData: (query: FilterQueryParams, page: PageInfo) => void,
    fetchItem: (renderId: string) => void,
    exportData: (query: FilterQueryParams) => void,
    setSelectedRow: (val: any) => void,
  },
  token: string | null
}

const defaultState : IAppState = {
  isWorking: false,
  items: [],
  actions: {
    setWorking: (val) => {},
    setPage: (val) => {},
    fetchData: (q, p) => {},
    fetchItem: (id) => {},
    exportData: (q) => {},
    setSelectedRow: (val) => {},
  },
  token: null
};

export const AppStateContext = React.createContext(defaultState);

const getStartOfYesterday = () => {
  return format(subDays(new Date((new Date()).toDateString()), 1), DATEPICKER_FORMAT);
}

//====
// Provider that will hold the reporting app state
export const AppStateProvider : React.FC = ({children}) => {
  const [isWorking, setWorking] = useState(false);
  const [page, setPage] = useState<PageInfo | undefined>(undefined);
  const [items, setItems] = useState<ReportRow[]>([]);
  const [error, setError] = useState<IPayloadError | null>(null);
  const [pagination, setPagination] = useState<PaginationInfo | undefined>(undefined);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const reportConfig = useReportConfig();
  const { client, subdomain } = useCustomizations();
  let token = useMemo(() => getSearchParam("token", true) ?? null, []);
  const apiKey = useMemo(() => getSearchParam("apiKey", true) ?? null, []);

  token = token ?? apiKey;

  //Once, on startup, check the url to see if there's a row id passed in.
  useEffect(() => {
    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    if(searchParams.has("rowid")){
      const rowId = searchParams.get("rowid");
      console.log("search string row id:", rowId);
      selectRow(rowId);
      //TODO: if the row isn't part of the current resultset, then we need to load that item separately...
    }
    else{
      console.log("no row id in search string");
    }
  }, []);

  const setPayload = (data: ReportPayload, isRefetch = false) => {
    if(data.isError){
      setError(data);
      return;
    }

    const pagination = parsePagination(data);
    setPagination(pagination); //data.options);
    const items = data.items ?? [];
    //TODO: is this necessary?  This may be handled by the custom row generation now. Need to check.
    const rows = items.map(i => {
      return {
        ...i, 
        id: i.renderId, 
        renderParametersJson: JSON.parse(i.renderParametersJson as any)
      } as ReportRow; 
    });

    console.log("setting items");
    setItems(rows);

    //If there's a row id, and this is the first time...
    if(!isRefetch && selectedRow){
      //Look to see if the selected row exists in the dataset
      const match = rows.findIndex(row => row.id === selectedRow);
      if(match < 0){
        //Filter on just this row
        const query = { filter: `renderId~${selectedRow}`};

        fetchData(query, {number: 0, size: 25}, true);
      }
    }
  }

  const fetchData = async (query: FilterQueryParams, page: PageInfo, isRefetch = false) => {
    setWorking(true);
    setError(null);
    // getAppInsights().trackEvent({ name: "Report.Refresh" }, {});
    //default to a filter of one day
    const filter = query.filter || `startDate~${getStartOfYesterday()}`
    console.log("fetching data");
    const data = await getReportData(client, subdomain, page.number, page.size, query.sort, filter, token);
    setPayload(data, isRefetch);
    setWorking(false);
  }

  const fetchItem = async (renderId: string) => {
    setWorking(true);
    const itemEvents = await getReportDetails(client, subdomain, renderId, token);
    const newItems = replaceItemDetails(items, renderId, itemEvents);
    if(newItems && newItems !== items) setItems(newItems);
    setWorking(false);
  }

  const exportData = async (query: FilterQueryParams) => {
    setWorking(true);
    //default to a filter of one day
    const filter = query.filter || `startDate~${getStartOfYesterday()}`

    await getReportDownload(client, subdomain, query.sort, filter, token);
    setWorking(false);
  }

  const selectRow = (rowId: string | null) => {
    console.log("changing selected row: ", rowId);
    setSelectedRow(rowId);
  }

  const theContext : IAppState = {
    isWorking,
    page,
    pagination,
    items,
    error,
    selectedRow,
    reportConfig,
    actions: {
      setWorking,
      setPage,
      fetchData,
      fetchItem,
      exportData,
      setSelectedRow: selectRow,
    },
    token
  };

  return(
    <AppStateContext.Provider value={theContext}>
      {children}
    </AppStateContext.Provider>
  );
};