export const EVENT_ICONS : Record<string, string> = {
  "ai.ClickLandingPageButton": "mouse",
  "ai.ClickPlayerBranding": "mouse",
  "ai.InteractiveClickThrough": "mouse",
  "ai.SharedVideo": "share",
  "ai.Video.Begin": "play_arrow",
  "ai.Video.End": "stop",
  "ai.ViewVideo": "public",
  "sendgrid.click": "mouse",
  "sendgrid.delivered": "mail",
  "sendgrid.open": "drafts",
  "sendgrid.processed": "settings",
  "unknown": "help_outline",
}