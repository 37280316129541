import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
// import { addDays, format, subDays } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ReportFilter from "./report-filter";
import ReportGrid from "./report-grid";
// import { DATE_FMT } from "../../helpers/general-helpers";
import { useAppState, usePage, useQueryString } from "../../helpers/hooks";
import LoadingIndicator from "components/loading-indicator";
import ErrorCard from "components/error-card";
import { PageInfo } from "app-types";
import { useDebouncedCallback } from "use-debounce/lib";

const DEFAULT_PAGE_SIZE = 25;
const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
    background: theme.palette.common.white,
    height: "100%",    
  },
  title   : {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: 0,
    fontSize    : 24,
    fontWeight  : 600,
  },
  grid: {

  }
}));

const ReportingView : React.FC = () => {
  const classes   = buildStyles();
  const history = useHistory();
  const [page, changePage] = usePage(DEFAULT_PAGE_SIZE);
  const [query, setQuery]   = useQueryString();
  const { actions, reportConfig, pagination, error, isWorking } = useAppState();

  //-- Called when the data needs to be refreshed because something changed
  // debounced so we don't trigger it multiple times when filter/sort which also needs to reset page num
  const onRefresh = useDebouncedCallback((query: any, page: PageInfo) => {
    // getAppInsights().trackEvent({ name: "Report.Refresh" }, {});
    actions.fetchData(query, page);
  }, 500);

  //Force the page to refresh.
  const doRefresh = () => {
    let myQuery = {
      ...query,
      sort: query.sort || "", //"queuedOnUTC-desc", //defaultSort,    //use defaults if nothing is manually set
      filter: query.filter || "", //defaultFilter
    };

    onRefresh(myQuery, page);
    // actions.fetchData(myQuery, page);
  };

  useEffect(() => {
    if(reportConfig){
      //Note, if data is set before report config is loaded, columns don't always render
      doRefresh();
    }
  }, [reportConfig]);

  useEffect(() => {
    if(reportConfig && !isWorking){
      onRefresh(query, page);    
    }
  }, [query.filter, query.find, query.sort, page.number, page.size]);

  const onFilterChange = useCallback((filterString: string) => {
    if(filterString !== (query.filter || "")){
      const url   = setQuery("filter", filterString);
      changePage(0);  //reset to page 1 since we're filtering. debounce should keep it from getting called 2x
      history.replace(url);
    }  

  }, [query.filter]);

  const onExport = () => {
    actions.exportData(query);
  }

  if(!reportConfig){
    return <LoadingIndicator message="Loading Grid Configuration..."/>;
  }
  else{
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid id="reporting-view" container wrap="nowrap" className={classes.root}>
          <Grid item container direction="column" alignItems="flex-start">        
            <Typography className={classes.title}>Reports</Typography>
            <ReportFilter config={reportConfig} classes={classes} currentFilter={query.filter} onFilter={onFilterChange} onExport={onExport} onRefresh={doRefresh} isWorking={isWorking}/>
            {error && <ErrorCard message={error.title} details={error.detail} />}
            {!error && <ReportGrid page={page} changePage={changePage} config={reportConfig} pagination={pagination}/>}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

export default ReportingView;

const gridFilters = [
  {
    id: 0,
    key: "startDate",
    label: "Start Date",
    type: "date",
    defaultValue: "+0.days",
    maxValue: "+1.days"
    // defaultValue: () => format(subDays(new Date(), 1), DATE_FMT),
    // maxValue: () => format(addDays(new Date(), 1), DATE_FMT),
  },
  {
    id: 5,
    key: "to",
    label: "to",
    type: "span",
  },
  {
    id: 10,
    key: "endDate",
    label: "End Date",
    type: "date",
    defaultValue: "+0.days",
    maxValue: "+1.days"
    // defaultValue: () => format(new Date(), DATE_FMT),
    // maxValue: () => format(addDays(new Date(), 1), DATE_FMT),
  },
  {
    id: 20,
    key: "recipientName",
    label: "Recipient Name",
    tooltip: "Enter text to filter by recipient name",
    type: "text",
  },
  {
    id: 30,
    key: "videoTemplateId",
    label: "Video Templates",
    tooltip: "Choose a value to filter by Video Template",
    type: "select",
    emptyLabel: "All Video Templates",
    options: {
      itemsSource: "/campaigns",
      // itemsSource: "campaigns/universitymsg.com/pittcomingsoon",
      items: (props: any) => props?.filters?.campaigns || [],
      keyProp: "campaignId",
      labelProp: "displayName",
    }
  }
];