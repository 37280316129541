import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useAppState } from "../../helpers/hooks";
import ReportDetailSection from "./report-details-section";
import { copyTextToClipboard } from 'helpers/general-helpers';
import { useEffect } from 'react';

const ReportDetails : React.FC = () => {
  const classes   = buildStyles();
  const [copyResult, setCopyResult] = useState<Boolean | null>(null);
  const { items, selectedRow, actions, isWorking, reportConfig } = useAppState();
  
  const reportData = useMemo(() => {
    if(!selectedRow) return null;
    return items?.find(item => item.renderId === selectedRow);
  }, [selectedRow, items]);

  useEffect(() => {
    if(copyResult !== null){
      //show the notification for 5 seconds, then stop
      const handle = setTimeout(() => {
        setCopyResult(null);
      }, 5000);

      return () => {
        if(handle) clearTimeout(handle);
      }
    }
  }, [copyResult]);

  if(!selectedRow || !reportData) return null;

  const getDeepLink = async () => {
    var parentUrl = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;
    const suffix = parentUrl.indexOf("report") >= 0 ? "" : "reports";
    const rowId = parentUrl.indexOf("rowid=") >= 0 ? "" : `rowid=${selectedRow}`;
    const connector = rowId ? (parentUrl.indexOf("?") > 0 ? "&" : "?") : "";
    const copyUrl = `${parentUrl}${suffix}${connector}${rowId}`;

    const wasCopied = await copyTextToClipboard(copyUrl);
    setCopyResult(wasCopied ?? false);
  }

  return (
    <Grid id="report-details" container justify="center" className={classes.root}>

      <Grid container justify="space-between" alignItems="center" className={classes.titleRow}>
        <Typography variant="h5" className={classes.title}>{reportConfig?.details?.title || "Report Information"}</Typography>
        <Grid item>
          <Button onClick={getDeepLink} disabled={isWorking} size="small" variant="outlined" className={classes.actionButton}>Share</Button>
          <Button onClick={() => actions.fetchItem(reportData?.renderId)} disabled={isWorking} size="small" variant="outlined" className={classes.actionButton}>Refresh</Button>
          <Button onClick={() => actions.setSelectedRow(null)} disabled={isWorking} size="small" variant="outlined" className={classes.actionButton}>Close</Button>
        </Grid>
        {copyResult !== null && 
          <Grid item container justify="flex-end">
            <Typography className={copyResult === false ? classes.notifyError : classes.notify}>{copyResult === false ? "Failed to copy to clipboard" : "Link copied to clipboard"}</Typography>
          </Grid>
        }
      </Grid>
      
      <Grid container direction="column">
        {reportConfig?.details?.sections?.map(section => <ReportDetailSection key={section.id} section={section} reportData={reportData} />)}
      </Grid>
    </Grid>
  );
}

export default ReportDetails;

const buildStyles   = makeStyles(theme => ({
  root  : {
    width: "60vw",
    padding     : theme.spacing(3),
  },
  titleRow: {
    marginBottom: theme.spacing(3),
  },
  title   : {
    fontSize    : 20,
    fontWeight  : 700,
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  notify: {
    fontSize: 15,
    marginTop: theme.spacing(0.5),
    color: theme.palette.success.main,
  },
  notifyError: {
    fontSize: 15,
    marginTop: theme.spacing(0.5),
    color: theme.palette.error.main,
  }
}));