import { useCallback, useRef, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { mergeComplexArrays, mergeComplexObjects, updateUrlSearch } from "./general-helpers";
import { PageInfo, ChangePageFunc, ReportGridConfig, FilterQueryParams, ReportFilterConfig } from "app-types";
import { AppStateContext } from "../app-state";
import { fetchJsonFile, useCustomizations } from "@personicom/customizations";
import { orderBy, values, merge, keyBy } from "lodash";
import { IReportDetailsConfig } from "features/report-details/report-detail-types";

//===
// Manage the relevant parameters in the querystring
export function useQueryString() : [FilterQueryParams, (key: string, value: string | null) => string] {
  const urlp = new URLSearchParams(useLocation().search);
  const sort = urlp.get("sort") || "";
  const filter = urlp.get("filter") || "";
  const find = urlp.get("find") || "";
  const rowId = urlp.get("rowid") || "";

  let result: FilterQueryParams = {};
  if (sort) result.sort = sort;
  if (filter) result.filter = filter;
  if (find) result.find = find;
  if(rowId) result.rowId = rowId;

  return [result, updateUrlSearch];
}


//===
// Manage the current grid page
export function usePage(defaultPageSize: number) :[PageInfo, ChangePageFunc] {
  const [page, setPage] = useState({ number: 0, size: defaultPageSize || 20 });

  function change(pageNum: number, pageSize: number | undefined = undefined) : void {
    if (pageNum === undefined && pageSize === undefined) return;
    const myNum = pageNum || 0; //if they've provided a new page size, reset to page 0
    const mySize = pageSize || page.size;
    setPage({ number: myNum, size: mySize });
  }

  return [page, change];
}

export function useAppState() {
  const appState = useContext(AppStateContext);
  return appState;
}

//----
// Loads the reporting configuration from blob storage.  Gets both the
// brand-level and the subdomain-level config, and merges them together.
export function useReportConfig(){
  const { blobSubdomainUrl, blobRootUrl } = useCustomizations();
  const [config, setConfig] = useState<undefined | ReportGridConfig>(undefined);
//
  async function loadConfig(){

    //Get the common config for this brand / client
    const path = blobRootUrl("reporting.json");
    const brandConfig = await fetchJsonFile(path); 
    
    //Get the subdomain / school specific config
    const sdPath = blobSubdomainUrl("reporting.json") as string;
    const subdomainConfig = await fetchJsonFile(sdPath);

    if(subdomainConfig){
      if(subdomainConfig.isError){
        console.error("Failed to load subdomain report configuration, see previous error in console.");
        setConfig(brandConfig); 
      }
      else{
        //deep merge by key
        let cols = values(merge( keyBy(brandConfig.columns, 'field'), keyBy(subdomainConfig.columns, 'field')));
        /*let cols = [
          ...brandConfig.columns,
          ...subdomainConfig.columns,
        ];*/

        let events = {
          ...brandConfig.events,
          ...subdomainConfig.events,
        };

        let filters = mergeComplexArrays(brandConfig.filters, subdomainConfig.filters, "key") as ReportFilterConfig[];
        let details : IReportDetailsConfig = mergeComplexObjects(brandConfig.details, subdomainConfig.details) as IReportDetailsConfig;
        // {
        //   ...brandConfig.details,
        //   ...subdomainConfig.details,
        //   sections: mergeComplexArrays(brandConfig.details?.sections, subdomainConfig.details?.sections) as IReportDetailSection[]
        // };

        //If any of the columns have an ordinal, then use that to order them
        const withOrdinal = cols.find(c => c.ordinal);
        if(withOrdinal){
          cols = orderBy(cols, c => c.ordinal);
        }

        const combined = {
          ...brandConfig, 
          ...subdomainConfig,
          columns: cols,
          events,
          filters,
          details,
        };

        setConfig(combined);
      }
    }
    else{
      setConfig(brandConfig);  
    }
  }

  useEffect(() => {
    if(!!blobRootUrl) loadConfig();
  }, []);

  return config;
}

//---
// Hook to call a function after a specificed timeframe.
export type UseTimeoutFnReturn = [() => boolean | null, () => void, () => void];

export default function useTimeoutFn(fn: Function, ms: number = 0): UseTimeoutFnReturn {
  const ready = useRef<boolean | null>(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const callback = useRef(fn);

  const isReady = useCallback(() => ready.current, []);

  const set = useCallback(() => {
    ready.current = false;
    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, ms);
  }, [ms]);

  const clear = useCallback(() => {
    ready.current = null;
    timeout.current && clearTimeout(timeout.current);
  }, []);

  // update ref when function changes
  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  // set on mount, clear on unmount
  useEffect(() => {
    set();

    return clear;
  }, [ms]);

  return [isReady, clear, set];
}