import { CSSProperties } from "react";
import { Chip, Tooltip, Typography } from "@material-ui/core";
import { green, yellow } from '@material-ui/core/colors';
import { DateWithoutTz } from "helpers/general-helpers";
import { format, sub } from "date-fns";
import { GridCellParams, GridRowData, GridValueFormatterParams, LicenseInfo } from "@material-ui/x-grid";
import { FormatterArgs } from "app-types";
import { isEmpty, isNull, isObject, isString, isUndefined } from "lodash";
import StatusChip from "components/status-chip";
import { useCustomizations } from "@personicom/customizations";

const XGRID_KEY = "4c50266618d0c8382c443d12c989a9bcT1JERVI6MjU1NDQsRVhQSVJZPTE2NTQyNzUwMjIwMDAsS0VZVkVSU0lPTj0x";
const DATE_FORMAT = "M/d/yy h:mm:ss aaa";

const fontStyle: CSSProperties = {
  fontSize: "inherit",
  fontWeight: "inherit",
  fontFamily: "inherit",
};

export const initializeXGrid = () => {
  LicenseInfo.setLicenseKey(XGRID_KEY);
}

//---
//Formats a date column
export const dateFormatter = (params: GridValueFormatterParams) => {
  const v = params.value;
  if (v) {
    const loc = DateWithoutTz(v.toString()); //moment(v).local();
    return format(loc, DATE_FORMAT);
  }

  return v;
}

//---
// Determins the correct Player Url based on the current environment.
export const playerUrlFormatter = (environment:string, client:string, subdomain:string) =>  (params: GridValueFormatterParams) => {
  const v = params.value;
  const row = params.row;
  client = client.toLowerCase();
  environment = environment.toLowerCase();
  const ENV_PREFIX = {
    development: "dev",
    qa: "qa"
  };
  
  var prefixEnvironment = (ENV_PREFIX as any)[environment];

  const campaignId = row['videoTemplateId'];
  const SID = getJsonField(row, 'renderParametersJson', 'SID');
  const renderId = row['renderId'];
  const campaignQualified = campaignId ? `${campaignId}/` : "";

  if (v) {
    //TODO: Move this to a Customization function or something based off PlayerUrlTemplate
    if (client == 'universitymsg.com') {
      //UM: qa.universitymsg.com/subdomain/?sid=A123
      //UM: subdomain.universitymsg.com/?sid=A123
      if(environment == 'production')
      {
        return `${subdomain}.${client}/?sid=${SID??renderId}`
      } else {
        return `${prefixEnvironment}.${client}/${subdomain}/?sid=${SID??renderId}`
      }
    } else {
      //SWA: player.smartsourcevideos.com/{CampaignId}/{renderId}
      if(environment == 'production')
      {
        return `player.${client}/${subdomain}/${campaignQualified}${SID??renderId}`
      } else {
        return `player-${prefixEnvironment}.${client}/${subdomain}/${campaignQualified}${SID??renderId}`
      }        
    }
  }

  return v;
}

const getJsonField = (row: GridRowData, jsonProp: string, fieldProp: string) => {
  // const row = params.row;
  const jsonValue = row[jsonProp];
  const json = isString(jsonValue) ? JSON.parse(jsonValue) : jsonValue;
  const value = json[fieldProp.trim()];
  return value;
}

//---
//Extracts a field value out of a json structure
//EXAMPLE:
//{
//   "formatter": "json",
//   "formatterArgs": "renderParametersJson",
//   "field": "recipientEmail",
//   "headerName": "Sent To",
// },
export const jsonFormatter = (args: FormatterArgs) => (params: GridValueFormatterParams) => {
  const row = params.row;
  const jsonProp = isObject(args) ? args.jsonProp : args;
  const value = getJsonField(row, jsonProp, params.colDef.field);
  return value;
}

//---
//Extracts multiple field value out of a json structure, and combines them with spaces between
//EXAMPLE:
// {
//   "formatter": "jsonCombine",
//   "formatterArgs": {
//     "jsonProp": "renderParametersJson",
//     "separator": ", "
//   },
//   // or "formatterArgs": "renderParametersJson" for default separator of space
//   "field": "lastName, firstName",
//   "headerName": "Recipient",
// },
export const jsonCombineFormatter = (args: FormatterArgs) => (params: GridValueFormatterParams) => {
  const row = params.row;
  const jsonProp = isObject(args) ? args.jsonProp : args;
  const separator = isObject(args) ? args.separator || " " : " ";

  const fields = params.colDef.field.split(",") as string[];
  const fieldValues = fields.map(f => getJsonField(row, jsonProp, f));
  const value = fieldValues.join(separator);
  return value;
}

//---
//takes the first non-null, non-blank value out of multiple field values
//EXAMPLE:
// {
//   "formatter": "coalesce",
//   "field": "renderParametersJson.recipientEmail, userEmail",
//   "headerName": "Recipient Email",
// }
export const coalesceFormatter = (params: GridValueFormatterParams) => {
  const row = params.row;

  const fields = params.colDef.field.split(",") as string[];
  const fieldValues = fields.map(f => {
    if (f.indexOf(".") > 0) {
      //means a json property
      const parts = f.split(".");
      return getJsonField(row, parts[0].trim(), parts[1].trim());
    }
    else {
      return row[f.trim()];
    }
  });

  const value = fieldValues.find(v => !isNull(v) && !isUndefined(v) && !isEmpty(v));
  return value;
}

export const LinkCell = (args: any) => (params: any) => {
  const { label, isDownload, isMail } = args ?? {};
  const val = params.colDef.valueFormatter ? params.colDef.valueFormatter(params) : params.value;

  const url = isMail ? `mailto://${val}` : (!val || val.indexOf("http") === 0) ? val : `http://${val}`;
  if (!val) return <span />;

  //So this doesn't trigger the row selection
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  }

  return (
    <a href={url} target="_blank" title={`click to open: ${val}`} download={Boolean(isDownload)} onClick={onClick}>
      {label || val}
    </a>
  );
};


export const TipCell = (args: any) => (params: GridCellParams) => {
  const val = params.colDef.valueFormatter ? params.colDef.valueFormatter(params) : params.value;

  return (
    <Tooltip title={val}>
      <Typography style={fontStyle}>{val}</Typography>
    </Tooltip>
  );
};

//= ({ status } : {status: string})
export const ReportStatus = (args: any) => (params: any) => {
  return <StatusChip status={params.value} />
  // let chipStyle : CSSProperties = {height: 24};
  // const status = params.value;

  // switch(status){
  //   case "Rendered":
  //     chipStyle = {...chipStyle, background: green[500], color: "#FFF"};
  //     break;

  //     case "Queued":
  //       chipStyle = {...chipStyle, background: yellow[500], color: "#000"};
  //       break;
  //   }

  // return <Chip label={status} style={chipStyle} />;
};