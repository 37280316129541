import React from "react";
import ErrorCard from "./error-card";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorCard 
        message="We're sorry, you've encountered an unexpected error.  We apologize for the inconvenience.  You can try refreshing the page, or clicking the back button to recover." 
        details={this.state.error.message} />
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
