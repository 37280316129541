import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { GridRowId, XGrid } from "@material-ui/x-grid";
import { ChangePageFunc, PageInfo, PaginationInfo, ReportGridConfig } from "app-types";
import { useCallback, useState } from "react";
import { useAppState, useQueryString } from "helpers/hooks";
// import { dateFormatter, LinkCell, TipCell } from "components/xgrid-helpers";
import { getGridColumns } from "./reporting-helpers";
import LoadingIndicator from "components/loading-indicator";
import { useCustomizations } from "@personicom/customizations";

const buildStyles   = makeStyles((theme : any) => ({
  root  : {
    padding     : `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`,
  },
  content: {
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    }
  },
  gridContainer: (props: any) => ({
    height: props.noFrame ? "calc(100vh - 127px)" : "calc(100vh - 300px)", 
    width: "100%"
  }),
}));

interface ReportGridProps {
  config: ReportGridConfig;
  pagination?: PaginationInfo;
  page: PageInfo;
  changePage: ChangePageFunc;
}

const ReportGrid : React.FC<ReportGridProps> = ({config, pagination, page, changePage}) => {
  const { isIframe } = useCustomizations();
  const classes   = buildStyles({noFrame: isIframe});
  const history = useHistory();
  const { isWorking, items, actions, selectedRow } = useAppState();
  const [selection, setSelection] = useState<GridRowId[]>([]);
  const [query, setQuery]   = useQueryString();
  var { environment, client, subdomain } = useCustomizations();

  const columns = getGridColumns(config, environment, client, subdomain);

  const onPageChange = useCallback((params) => {  
    changePage(params.page, params.pageSize);
  }, []);

  const onSortChange = useCallback((params) => {
    const sort = params.sortModel.length ? params.sortModel[0] : null;
    const sortCol = sort ? config.columns.find(c => c.field === sort.field) : null;
    const sortString =  sort ? `${sortCol?.sortKey || sort.field}-${sort.sort}` : "";
    
    const url = setQuery("sort", sortString)
    history.replace(url);
  }, []);

  const onSelectionChange = (newSelection: any) => {
    
    const model = newSelection.selectionModel;
    let rowId : string | null;
    if(model && model.length > 0 && model[0] === selectedRow){
      rowId = null;
    }
    else{
      rowId = model[0];
    }
    
    setSelection(model);
    actions.setSelectedRow(rowId);
    const url = setQuery("rowid", rowId)
    history.replace(url);    
  } 

  if(!items || !config){
    return <LoadingIndicator message="Initializing grid..." />
  }

  return (
    <Grid id="report-grid-view" container justify="center" className={classes.root}>
      <Grid item xs={12} className={classes.content}>
        <div className={classes.gridContainer}>
          <XGrid
            loading={isWorking}          
            rows={items || []}
            columns={columns}
            disableColumnFilter
            page={page.number}
            pageSize={page.size}
            density="compact"
            pagination
            paginationMode="server"
            rowCount={pagination?.totalCount || 0}
            onPageChange={onPageChange}
            onPageSizeChange={onPageChange}
            sortingMode="server"
            onSortModelChange={onSortChange}
            onSelectionModelChange={onSelectionChange}
            selectionModel={selection}  //[selectedRow]
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default ReportGrid;