import React from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IReportDetailRow } from "./report-detail-types";
import RowValue from "./table-section-row-value";

const buildStyles   = makeStyles(theme => ({
  label: {
    fontSize: 12,
    color: theme.palette.grey[700],
    fontWeight: 300,
  },
}));

interface ISectionRowProps {
  row: IReportDetailRow;
  data: any;
}

const TableSectionRow : React.FC<ISectionRowProps> = ({row, data}) => {
  const classes = buildStyles();
  
  return (
    <TableRow>
      <TableCell>
        <Typography className={classes.label}>{row.label}</Typography>
      </TableCell>
      <TableCell>
        <RowValue row={row} data={data} />
      </TableCell>
    </TableRow>
  );
};

export default TableSectionRow;
