import { useMemo } from 'react';
import React, { useState } from 'react';
import { addDays, format, isAfter, isBefore } from 'date-fns';
import { IFilterFieldProps } from '../reporting-types';
import DateFilter, { DATEPICKER_FORMAT } from "./date-filter";
import { Grid } from '@material-ui/core';
import { getDateFromProperty } from '../reporting-helpers';

//---- Date Range Filter Field
const DateRangeFilter : React.FC<IFilterFieldProps> = ({config, cols, filters, classes, isWorking, onChange}) => {
  const rangeStart = useMemo(() => config.options?.rangeStart, [config]);
  const startDefault = useMemo(() => getDateFromProperty(config.options?.rangeStart?.defaultValue) as Date, [config]);
  const rangeEnd = useMemo(() => config.options?.rangeEnd, [config]);
  const endDefault = useMemo(() => getDateFromProperty(config.options?.rangeEnd?.defaultValue) as Date, [config]);
  
  const [startValue, setStartValue] = useState<Date | null>(null);
  const [endValue, setEndValue] = useState<Date | null>(null);

  if(!rangeStart || !rangeEnd) return null;

  const getActualValue = (value: any, previousValue: Date, defaultValue: Date) => {
    const previousStrValue = format(previousValue, DATEPICKER_FORMAT);
    if(value === previousStrValue) return ["", "", "nochange"];
    else if(!value) return ["", defaultValue, "empty"];
    else return [value, new Date(value), "change"];
  }

  const onRangeChange = (field: any, value: any) => {
    const changeKeys = [];
    const changeValues = [];
    
    if(field === rangeStart.key){
      //start date has changed
      const [strVal, dtVal, status] = getActualValue(value, startValue ?? startDefault, startDefault);
      if(status === "empty" || status === "change"){
        changeKeys.push(rangeStart.key);
        changeValues.push(format(dtVal, DATEPICKER_FORMAT));  //start date is always required, so use the default value here
        setStartValue(dtVal);
        
        //determine if the start date is after the current end date, and adjust accordingly.
        if(isAfter(dtVal, endValue ?? endDefault)){
          const newEnd = addDays(dtVal, 1);
          const newEndStr = format(newEnd, DATEPICKER_FORMAT);
          changeKeys.push(rangeEnd.key);
          changeValues.push(newEndStr);
          setEndValue(newEnd);
        }
      }
    }
    else{
      //end date has changed
      const [strVal, dtVal, status] = getActualValue(value, endValue ?? endDefault, endDefault);
      if(status === "empty" || status === "change"){
        changeKeys.push(rangeEnd.key);
        changeValues.push(format(dtVal, DATEPICKER_FORMAT));  //start date is always required, so use the default value here
        setEndValue(dtVal);
        
        //determine if the end date is before the current start date, and adjust accordingly.
        if(isBefore(dtVal, startValue ?? startDefault)){
          const newStart = addDays(dtVal, -1);
          const newStartStr = format(newStart, DATEPICKER_FORMAT);
          changeKeys.push(rangeStart.key);
          changeValues.push(newStartStr);
          setStartValue(newStart);
        }
      }
    }

    if(changeKeys.length > 0){
      onChange(changeKeys, changeValues);
    }    
  }

  return (
    <Grid container item spacing={1}>
      <Grid item>
        <DateFilter config={rangeStart} cols={cols} filters={filters} classes={classes} isWorking={isWorking} onChange={onRangeChange} />
      </Grid>
      <Grid item>
        <DateFilter config={rangeEnd} cols={cols} filters={filters} classes={classes} isWorking={isWorking} onChange={onRangeChange} />
      </Grid>
    </Grid>
  )
}

export default DateRangeFilter;