import React, { useMemo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Paper, Typography, Icon, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { EVENT_ICONS } from "./event-icons";
import { formatDate } from "../reporting/reporting-helpers";
import { EventConfig, IEventLocation, IReportMetadata, ITimelineEvent } from "./report-timeline-types";

interface IControlProps {
  event: ITimelineEvent;
  config?: Record<string, EventConfig>;
  isWorking?: boolean;
}

interface ISubControlProps {
  event: ITimelineEvent;
  config: EventConfig | null;
}

const TimelineItem : React.FC<IControlProps> = ({event, config, isWorking}) => {
  const classes   = buildStyles();
  const { data, eventType } = event;
  const itemConfig = useMemo(() => config ? config[eventType] : null, [config, eventType]);

  if(itemConfig?.isHidden) return null;

  if(data){
    return (
      <Accordion TransitionProps={{ unmountOnExit: true }} disabled={isWorking}>
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <TimelineItemContent event={event} config={itemConfig}/>
        </AccordionSummary>
        <TimelineItemDetails event={event} config={itemConfig} />
      </Accordion>
    );
  }
  else{
    return (
      <Paper elevation={0} className={classes.paper}>
        <TimelineItemContent event={event} config={itemConfig} />
      </Paper>
    );
  }

}

export default TimelineItem;

const buildStyles   = makeStyles(theme => ({  
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {
    fontSize: 12,
    color: theme.palette.grey[700],
    fontWeight: 300,
    // marginBottom: theme.spacing(0.5),
  },
  timestamp: {
    fontSize: 13,
    fontWeight: 500,
  },
  value: {
    fontSize: 14,
    // color: theme.palette.grey[400],
    fontWeight: 500,
    // marginBottom: theme.spacing(1),
  },
  paper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
    paddingRight: theme.spacing(7),
  },
  detailPanel: {
    background: theme.palette.grey[100],
    paddingRight: theme.spacing(2),
  }
}));

const getEventValue = (propName: string, eventData: any) : string => {
  const parts = propName.split(".");
  var values = eventData.meta[0][parts[0]];

  if(parts[0] === "location"){
    return values[parts[1]] || "?";
  }
  else if(parts[0] === "tags"){
    if (!Array.isArray(values))
    {
      values = Object.keys(values).map( (key) => ({ [key] : values[key] }) );
    }
    const val = values.find((v: any) => Object.keys(v)[0] === parts[1]);
    return val[parts[1]] || "?";
  }
  return values[parts[1]] || "?";
}

const EXPR = /{(.*?)}/g;
const swapValues = (displayProp: string, eventData: any) : string => {

  let output = displayProp;
  let match = null;
  do{
    match = EXPR.exec(displayProp);
    if(match){
      const val = getEventValue(match[1], eventData);
      output = output.replace(match[0], val);
      // console.log(match[0], match[1]);
    }

  } while (match);

  return output;
}

const EventDisplayProp : React.FC<ISubControlProps> = ({event, config}) => {
  const classes   = buildStyles();
  
  const value = useMemo(() => {
    if(!config?.displayProp) return null;
    if(config.displayProp.indexOf("{") >= 0){
      const eventData = JSON.parse(event.data);    
      const output = swapValues(config.displayProp, eventData);
      return output;
    }
    else return config.displayProp;    
  }, [event, config]);

  if(!value) return null;

  return (
    <Grid item>
      <Grid container justify="center">
        <Typography className={classes.timestamp}>{value}</Typography>  
      </Grid>
    </Grid>
  );
}

const TimelineItemContent : React.FC<ISubControlProps> = ({event, config}) => {
  const classes   = buildStyles();
  const { timestamp } = event;
  const icon = useMemo(() => config?.icon ?? EVENT_ICONS["unknown"], [config]); //EVENT_ICONS[eventType] ?? EVENT_ICONS.unknown, [eventType]);
  
  return (
    <Grid container justify="space-between">
      <Grid item>
        <Grid container alignItems="center">
          {icon && <Icon fontSize="small" className={classes.icon}>{icon}</Icon>}
          <Typography className={classes.label}>{config?.displayName}</Typography>
        </Grid>
      </Grid>
      <EventDisplayProp event={event} config={config} />
      <Grid item>
        <Grid container alignItems="center" justify="flex-end">
          <Typography className={classes.timestamp}>{formatDate(timestamp, true)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const TimelineItemDetails : React.FC<ISubControlProps> = ({event, config}) => {
  const classes = buildStyles();
  const { data } = event;
  const details = useMemo<IReportMetadata>(() => data ? JSON.parse(data).meta[0] : {}, [data]);

  if(!details) return null;
  const { location, tags } = details;

  return (
    <AccordionDetails className={classes.detailPanel}>
      <Table size="small">
        <TableBody>
          {location && <ItemLocation location={location} classes={classes} /> }
          {tags && tags.length > 0 && <ItemTags tags={tags} classes={classes} />}
        </TableBody>
      </Table>
    </AccordionDetails>
  );
}

interface ILocationProps {
  location: IEventLocation;
  classes: Record<string, any>;
}

const ItemLocation : React.FC<ILocationProps> = ({location, classes}) => {

  return (
    <>
      {location.continent && 
        <TableRow>
          <TableCell width="auto"><Typography className={classes.label}>Continent</Typography></TableCell>
          <TableCell><Typography className={classes.value}>{location.continent}</Typography></TableCell>
        </TableRow>
      }
      {location.country && 
        <TableRow>
        <TableCell width="auto"><Typography className={classes.label}>Country</Typography></TableCell>
        <TableCell><Typography className={classes.value}>{location.country}</Typography></TableCell>
      </TableRow>
      }
      {location.province && 
        <TableRow>
        <TableCell width="auto"><Typography className={classes.label}>Province</Typography></TableCell>
        <TableCell><Typography className={classes.value}>{location.province}</Typography></TableCell>
      </TableRow>
      }
      {location.city && 
        <TableRow>
        <TableCell width="auto"><Typography className={classes.label}>City</Typography></TableCell>
        <TableCell><Typography className={classes.value}>{location.city}</Typography></TableCell>
      </TableRow>
      }
      {location.clientIp && 
        <TableRow>
        <TableCell width="auto"><Typography className={classes.label}>IP Address</Typography></TableCell>
        <TableCell><Typography className={classes.value}>{location.clientIp}</Typography></TableCell>
      </TableRow>
      }
    </>
  );
}

interface ITagProps {
  tags: Record<string, any>[];
  classes: Record<string, any>;
};

const ItemTags : React.FC<ITagProps> = ({tags, classes}) => {
  let i = 0;
  return (
    <>
    {
      tags.map(tag => {
        return (
          <React.Fragment key={i++}>
          {
            Object.keys(tag).map(key => {
              return (
                <TableRow key={i++}>
                  <TableCell width="auto"><Typography className={classes.label}>{key}</Typography></TableCell>
                  <TableCell><Typography className={classes.value}>{tag[key]}</Typography></TableCell>
                </TableRow>
              )
            })
          }
          </React.Fragment>
        )
      })
    }
    </>
  );
};