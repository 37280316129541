//------------------

import { PaginationInfo } from "app-types";

// Handle a blob/arraybuffer response and trigger the download
const CD_HEADER = "content-disposition";
const CD_HEADER_CAP = "Content-Disposition";    //historically, it has come back both ways

export async function doFetch(url: string, method: string, body: any) {

  try {
    const result = await fetch(url, { method: method, body: body });
    if (result.json) {
      const json = await result.json();

      if (!result.ok) {
        return {
          isError: true,
          status: result.status,
          message: result.statusText,
          ...json
        };
      }

      return json;  //all good, just return the result
    }
    else if (result.ok === true) {
      //Nothing to return, but all is good
      return result;
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      message: errMsg
    };
  }
}


export async function doFetchWithToken(url: string, method: string, body: any, token: string) {

  try {
    const headers = {
      //Azure Static Web apps currently don't support passing the 'authorization' header through the proxy to the Azure Function app. They 
      //use this header name for their own purposes.
      //So we make up our own.
      "x-personicom-auth": "Bearer " + token
    };

    const result = await fetch(url, { method: method, body, headers });
    if (result.json) {
      const json = await result.json();

      if (!result.ok) {
        return {
          isError: true,
          status: result.status,
          message: result.statusText,
          ...json
        };
      }

      return json;  //all good, just return the result
    }
    else if (result.ok === true) {
      //Nothing to return, but all is good
      return result;
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      message: errMsg
    };
  }
}

export async function doFileFetch(url: string, method: string, body: any, contentType: string, prepareResult: any) {
  try {
    const result = await fetch(url, { method: method, body });
    if (result.ok) {
      const prepared = prepareResult(result);
      const isOk = await downloadFile(prepared, contentType);
      if (!isOk) {
        return {
          isError: true,
          // status: result.status,
          message: result.statusText,
          ...result,
        };
      }
      else {
        return {
          isError: false,
          ...result,
        }
      }
    }
    else {
      return {
        isError: true,
        error: result.statusText || "Failed to fetch file for download",
        ...result,
      }
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      error: errMsg
    };
  }
}

export async function doFileFetchWithToken(url: string, method: string, body: any, contentType: string, prepareResult: any, token: string) {
  try {
    const headers = {
      //Azure Static Web apps currently don't support passing the 'authorization' header through the proxy to the Azure Function app. They 
      //use this header name for their own purposes.
      //So we make up our own.
      "x-personicom-auth": "Bearer " + token
    };

    const result = await fetch(url, { method: method, body, headers });
    if (result.ok) {
      const prepared = prepareResult(result);
      const isOk = await downloadFile(prepared, contentType);
      if (!isOk) {
        return {
          isError: true,
          // status: result.status,
          message: result.statusText,
          ...result,
        };
      }
      else {
        return {
          isError: false,
          ...result,
        }
      }
    }
    else {
      return {
        isError: true,
        error: result.statusText || "Failed to fetch file for download",
        ...result,
      }
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      error: errMsg
    };
  }
}


export async function downloadFile(result: Response, mimeType: string) {
  const cd = result.headers.get(CD_HEADER) || result.headers.get(CD_HEADER_CAP);

  if (cd) {
    //The file to download was returned as a Blob in the response.
    let fileName = null;
    const cdParts = cd.split(';');
    const fname = cdParts.find(cdp => cdp.indexOf("filename=") >= 0);
    if (fname) {
      fileName = fname.trim().substr("filename=".length);
    }

    if (fileName && mimeType) {
      var linkElement = document.createElement('a');
      try {
        var data = await result.blob();
        var blob = new Blob([data], { type: mimeType });
        var url = window.URL.createObjectURL(blob);

        linkElement.setAttribute('href', url);
        linkElement.setAttribute("download", fileName);

        var clickEvent = new MouseEvent("click", {
          "view": window,
          "bubbles": true,
          "cancelable": false
        });
        linkElement.dispatchEvent(clickEvent);

        return true;    //indicate it worked

      } catch (ex) {
        console.log(ex);
      }
    }
  }

  return false;
}

export function parsePagination(data: Record<string, any>){
  const pagination : PaginationInfo = {
    hasNextPage: data.hasNextPage,
    hasPreviousPage: data.hasPreviousPage,
    pageIndex: data.pageIndex,
    totalCount: data.totalCount,
    totalPages: data.totalPages,
    filter: data.filter,
    sort: data.sort,
  };

  return pagination;
}