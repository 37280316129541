import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Table, TableBody, TableContainer, Typography } from "@material-ui/core";
import { IReportDetailSection } from "./report-detail-types";
import ReportTimeline from "features/report-timeline/report-timeline";
import TableSectionRow from "./table-section-row";
import { orderBy } from "lodash";

const buildStyles   = makeStyles(theme => ({
  sectionHeader: {
    fontSize    : 16,
    fontWeight  : 600,
    marginBottom: theme.spacing(1),
  },
  detailsTable: {
    borderBottom: "none",
    marginBottom: theme.spacing(3),
  },
}));

interface ISectionProps {
  section: IReportDetailSection;
  reportData: any;
}

const ReportDetailsSection : React.FC<ISectionProps> = ({section, reportData}) => {
  const classes   = buildStyles();

  if(!section || !reportData) return null;

  switch(section.type){
    case "timeline": return <ReportTimeline reportData={reportData} />;
    case "table":
    default:
      return <TableSection section={section} reportData={reportData} classes={classes} />;
  }  
}

export default ReportDetailsSection;

interface ITableSectionsProps {
  section: IReportDetailSection;
  reportData: any;
  classes: Record<string, any>;
};

//===
// Component for the Section.type of "table" (or empty, this is the default)
const TableSection : React.FC<ITableSectionsProps> = ({section, reportData, classes}) => {
  
  const sectionRows = useMemo(() => {
    const allRows = section.rows || [];
    const visibleRows = allRows.filter(r => r.hidden !== true);
    return orderBy(visibleRows, r => r.id);
  }, [section]);

  return (
    <div>
      <Typography className={classes.sectionHeader}>{section.title}</Typography>
        
        <TableContainer variant="outlined" elevation={0} component={Paper} className={classes.detailsTable}>
          <Table size="small">
            <TableBody>
              {sectionRows.map(row => <TableSectionRow key={row.id} row={row} data={reportData} />)}
            </TableBody>
          </Table>
        </TableContainer>

    </div>
  );
}