import React, { useMemo } from 'react';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { addMinutes, isAfter, isBefore } from 'date-fns';
import { IFilterFieldProps } from '../reporting-types';
import { getDateFromProperty, parseDateProp, startOrEndOfDay } from '../reporting-helpers';
import { isSameDay } from 'date-fns/esm';

// //DatePicker doesn't like slashes in the date value, so need two different formats here.
export const DATEPICKER_DISPLAY_FORMAT   = "MM/dd/yyyy";
export const DATEPICKER_FORMAT   = "yyyy-MM-dd";

//---- Date Filter Field
const DateFilter : React.FC<IFilterFieldProps> = ({config, filters, classes, isWorking, onChange}) => {
  const defaultValue = useMemo(() => getDateFromProperty(config.defaultValue) as Date, [config]); //default value is required
  const defaultProps = useMemo(() => parseDateProp(config.defaultValue), [config.defaultValue]);
  const minValue = useMemo(() => getDateFromProperty(config.minValue), [config]);
  const maxValue = useMemo(() => getDateFromProperty(config.maxValue), [config]);

  const currentValue = useMemo<Date>(() => {
    if(filters[config.key]){
      const strValue = filters[config.key];
      let dtValue = new Date(strValue);
      dtValue = addMinutes(dtValue, dtValue.getTimezoneOffset());
      const actualValue = startOrEndOfDay(dtValue, defaultProps?.offset ?? "start");
      // console.log("new current value:", soed);
      return actualValue;
    }
    else return defaultValue;    
  }, [filters, defaultValue, config, defaultProps]);

  const onDatePickerChange = (value: Date | null) => {
    if(!value && !!filters[config.key]){
      onChange(config.key, "");
    }

    else if(!!value){
      if(isNaN(value.getDate())) return;  //not a valid date

      //validate (if they entered via keyboard, it doesn't stop from being an invalid date)
      if((minValue && isBefore(value, minValue)) || (maxValue && isAfter(value, maxValue))) return;

      const dateWithOffset = startOrEndOfDay(value, defaultProps?.offset ?? "start");
      const newValueStr = dateWithOffset.toDateString(); //format(dateWithOffset, DATEPICKER_FORMAT); //dateWithOffset.toDateString();
        
      if(!isSameDay(dateWithOffset, currentValue)){ // newValueStr !== currentValue.toDateString()){  //make sure it's not the same as the current value
        
        //If this is the default value, then pass "" to clear the filter
        if(isSameDay(dateWithOffset, defaultValue)){ //newValueStr === defaultStr){ //defaultValue.toDateString()){  //if it's the default value, then pass up "" to clear this filter
          onChange(config.key, "");
          return;
        }
        // const dateString = dateWithOffset.toUTCString();
        console.log("new selected date: ", newValueStr, value);
        onChange(config.key, newValueStr);
      }      
    }
  }

  // console.log("current date value:", currentValue.toString());
  return (
    <KeyboardDatePicker 
      id={config.key} 
      className={classes.datePicker} 
      autoComplete="off" 
      inputVariant="outlined" 
      margin="dense" 
      format={DATEPICKER_DISPLAY_FORMAT} 
      placeholder={config.label}
      minDate={minValue} 
      maxDate={maxValue} 
      value={currentValue} 
      onChange={date => onDatePickerChange(date)} title={config.label} 
      disabled={isWorking}
    />
  )
};

export default DateFilter;